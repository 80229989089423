const baseUrl = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
const image_baseUrl = 'https://chownows3bucket.s3.af-south-1.amazonaws.com/';
// const image_baseUrl = 'https://www.gerechtaanhuis.nl/admin/public/';
//https://www.gerechtaanhuis.nl/admin/public/media/foodapp/original/food_logo_16975262471107.png
//const image_baseUrl = 'https://admin.liefertuete.de/public/';

let host = window.location.hostname;
let apiUrl = 'https://api.chownowapp.com/api/v1/';
let socketUrl = 'https://dashboard.chownowapp.com:8000';
let loadSharAPI = "https://api.loadshare.net/hyperlocal/v2/";

// let apiUrl = 'https://dev.deonde.co/deondeapi/api/v1/';
// let socketUrl = 'https://dev.deonde.co:8000';

if(host == 'localhost' || host == '192.168.0.15') {
    apiUrl = 'https://api.chownowapp.com/api/v1/';
    socketUrl = 'https://dashboard.chownowapp.com:8000';
    loadSharAPI = "https://api-staging.loadshare.net/hyperlocal/v2/";

    
    // apiUrl = 'https://www.gerechtaanhuis.nl/appapi/api/v1/';
    // socketUrl = 'https://www.gerechtaanhuis.nl:8000';
    // loadSharAPI = '';

    // apiUrl = 'https://dev.deonde.co/deondeapi/api/v1/';
    // socketUrl = 'https://dev.deonde.co:8000';    
    // apiUrl = 'https://deondeapi.test/api/v1';
    // socketUrl = 'https://dashboard.deonde.co:8000';
    
}

export default {
    appurl: baseUrl,    
    coupon: image_baseUrl + 'media/Coupon/',
    applogo: image_baseUrl + 'media/foodapp/original/',
    signInLogo: image_baseUrl + 'media/LoginSigupImage/',
    google_key: window.google_map_key,
    api_url: apiUrl,
    socket_url: socketUrl,
    loadSharAPI:loadSharAPI
}